import { useState } from 'react'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    const form = e.target;
    const data = new FormData(e.target)

    /**
     * Code for submitting form values
     * via an external API goes here.
     */
    
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://formspree.io/f/xrgorlqg');
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;

      if (xhr.status === 200) {
        form.reset();
        setSuccess(true)
        setSubmitting(false)
        setSubmitted(true)
      } else {
        setError(true)
        setSubmitting(false)
      }
    };
    xhr.send(data);
  }

  return {
    handleSubmit,
    submitting,
    submitted,
    success,
    error
  }
}

export default useForm
