import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'

const ContactForm = ({ handleSubmit, submitting, success, error }) => (
  <>
  <Helmet>
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
  </Helmet>
  <form onSubmit={handleSubmit}  action="https://formspree.io/f/xrgorlqg" method="POST">
    {success && (
      <Message variant='success'>
        Merci pour votre message. Je reviens vers vous rapidement!
      </Message>
    )}
    {error && !success && (
      <Message variant='error'>
        Message non envoyé. Avez vous bien prouvé que vous n'êtes pas un robot?
      </Message>
    )}
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Nom et Prénom</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input
          type='email'
          placeholder='email@example.com'
          id='contact-form-email'
          name='email'
          required
        />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-subject'>Sujet</Label>
      <Input type='text' id='contact-form-subject' name='subject' required />
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Votre Message</Label>
      <Textarea type="text" name='message' id='contact-form-message' />
    </Box>
    <Box sx={{my:`2rem`}}>
      <div class="g-recaptcha" data-sitekey="6LcIxAMbAAAAAD0W2WWTl6Tdh2kHF5QaI-pkRonB"></div>
    </Box>
    <Button
      variant={success || submitting ? 'disabled' : 'primary'}
      disabled={success || submitting}
      type='submit'
      required
    >
      Envoyer {submitting && <Spinner size='20' />}
    </Button>
  </form>
  </>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool
}
