import React from 'react'
import { graphql } from "gatsby"
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default ({data}) => (
  <>
    <Seo title='Contact | Comment devenir rentier' siteUrl="https://www.zen-option.com/formulaire-de-contact/"/>
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header={data.contactPageInfo.title}
          subheader={data.contactPageInfo.subtitle}
        />
        {renderRichText(data.contactPageInfo.content)}
        <ContactForm />
      </Main>
    </Stack>
  </>
)

export const query = graphql`
  query {
    contactPageInfo: contentfulContactPage {
      title
      subtitle
      content{
        raw
      }
    }

  }
`